<template>
  <div>
    <page-header class="mb-4">
      {{ $t('datevSetting') }}
      <template #action>
        <div class="d-flex">
          <v-item-group
            v-model="tab"
            mandatory
          >
            <div class="d-flex">
              <v-item v-slot="{active,toggle}">
                <v-btn
                  :color="active?'primary lighten-4 black--text':''"
                  :outlined="!active"
                  :style="active?'':'background-color: white'"
                  class="mr-1"
                  elevation="0"
                  rounded
                  @click="toggle"
                >
                  {{ $t('DatevExportDownload') }}
                </v-btn>
              </v-item>
              <v-item v-slot="{active,toggle}">
                <v-btn
                  :color="active?'primary lighten-4 black--text':''"
                  :icon="!$vuetify.breakpoint.mdAndUp"
                  :outlined="!active"
                  :style="active?'background-color: #ffff87':'background-color: white'"
                  class="mr-1"
                  elevation="0"
                  rounded
                  @click="toggle"
                >
                  <v-icon
                    color="black"
                  >
                    mdi-cog
                  </v-icon>
                  <div
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="ml-2"
                  >
                    {{ $t('editSetting') }}
                  </div>
                </v-btn>
              </v-item>
            </div>
          </v-item-group>
          <v-spacer/>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-btn
              v-if="tab === 1"
              class="primary"
              elevation="0"
              @click="saveConfirm"
            >
              <v-icon left>
                mdi-content-save-outline
              </v-icon>
              {{ $t('save') }}
            </v-btn>
          </template>
          <template v-else-if="tab === 1">
            <fab-button
              fab-bottom-val="90px"
              fab-fixed
              fab-right
              @click-event="saveConfirm"
            />
          </template>
        </div>
      </template>
    </page-header>
    <v-tabs-items
      v-model="tab"
      class="mt-4"
    >
      <v-tab-item>
        <v-card
          class="pa-4"
          elevation="0"
        >
          <div class=" mt-4">
            <div>
              <div class="font-weight-bold text-body-1">
                {{ $t('Hint') }}: {{ $t('NewestDatevHint') }}
              </div>
              <v-date-picker
                v-model="picker"
                :locale="$i18n.locale"
                :max="maxMonth"
                class="mt-4"
                type="month"
              />
            </div>
            <v-btn
              :disabled="canDownMonth > 0"
              class="mt-4 mr-2"
              color="primary lighten-4 black--text"
              elevation="0"
              @click="downDatevCsv"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              {{ $t('DatevDownload') }}
            </v-btn>
            <v-btn
              :disabled="canDownMonth > 0"
              class="mt-4"
              color="blue lighten-4 black--text"
              elevation="0"
              @click="sendDatev"
            >
              <v-icon left>
                mdi-email-send-outline
              </v-icon>
              {{ $t('OnlySentToMail') }}
            </v-btn>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
          class="mt-4"
          elevation="0"
        >
          <div>
            <v-tabs
              show-arrows
            >
              <v-tab>{{ $t('basicSetting') }}</v-tab>
              <v-tab>{{ $t('CashUsage') }}</v-tab>
              <v-tab>{{ $t('Paymentmethod') }}</v-tab>
              <v-tab>{{ $t('Source') }}</v-tab>

              <v-tab-item>
                <div class="pa-2">
                  <template v-for="(item,index) in notKontoForms ">
                    <div
                      :key="index"
                    >
                      <div class="text-overline">
                        {{ item.fieldName }}
                      </div>
                      <v-text-field
                        v-model="item.value"
                        :placeholder="item.description"
                        :rules="item.isRequired === '1' ? [() => !!item.value || 'This field should not be empty'] : []"
                        dense
                        hide-details
                        outlined
                      />
                      <div
                        class="text--secondary py-1"
                        style="font-size: 12px"
                      >
                        {{ item.description }}
                      </div>
                    </div>
                  </template>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <div class="font-weight-bold text-h4 mt-2">
                        {{ $t('PosCashIncome') }}
                      </div>
                      <template v-for="(item,index) in leftCashUse ">
                        <div
                          :key="index"
                        >
                          <div class="text-overline">
                            {{ item.name }}
                          </div>
                          <div class="d-flex">
                            <v-text-field
                              v-model="item.account"
                              :placeholder="item.name"
                              append-outer-icon="mdi-delete"
                              dense
                              hide-details
                              outlined
                              type="number"
                              @click:append-outer="deleteUseOfCash(item)"
                            />
                          </div>
                        </div>
                      </template>
                      <div class="d-flex align-center justify-center">
                        <v-btn
                          class="mt-4"
                          color="#25A18E"
                          elevation="0"
                          large
                          width="100%"
                          @click="openAddDialog(1)"
                        >
                          {{ $t('AddPrivateDeposit') }}
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="font-weight-bold text-h4 mt-2">
                        {{ $t('PosCashOut') }}
                      </div>
                      <template v-for="(item,index) in rightCashUse ">
                        <div
                          :key="index"
                        >
                          <div class="text-overline">
                            {{ item.name }}
                          </div>
                          <div class="d-flex">
                            <v-text-field
                              v-model="item.account"
                              :placeholder="item.name"
                              append-outer-icon="mdi-delete"
                              dense
                              hide-details
                              outlined
                              type="number"
                              @click:append-outer="deleteUseOfCash(item)"
                            />
                          </div>
                        </div>
                      </template>
                      <div class="d-flex align-center justify-center">
                        <v-btn
                          class="mt-4"
                          color="#25A18E"
                          elevation="0"
                          large
                          width="100%"
                          @click="openAddDialog(2)"
                        >
                          {{ $t('AddPrivatePayout') }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <div class="pa-2 d-flex flex-column">
                  <div class="font-weight-bold text-h4">
                    {{ $t('payMethod') }}
                  </div>
                  <template v-for="(item,index) in paymentList ">
                    <div
                      :key="index"
                    >
                      <div class="text-overline">
                        {{ item._langsname }}
                      </div>
                      <v-text-field
                        v-model="item.datevAccount"
                        :placeholder="item._langsname"
                        dense
                        hide-details
                        outlined
                        type="number"
                      />
                    </div>
                  </template>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div class="pa-2 d-flex flex-column">
                  <div class="font-weight-bold text-h4">
                    {{ $t('Source') }}
                  </div>
                  <template v-for="(item,index) in kontoForms ">
                    <div
                      :key="index"
                    >
                      <div class="text-overline">
                        {{ item.fieldName }}
                      </div>
                      <v-text-field
                        v-model="item.value"
                        :placeholder="item.description"
                        :rules="item.isRequired === '1' ? [() => !!item.value || $i18n.t('EmptyInput')] : []"
                        dense
                        hide-details
                        outlined
                      />
                      <div
                        class="text--secondary py-1"
                        style="font-size: 12px"
                      >
                        {{ item.description }}
                      </div>
                    </div>
                  </template>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-model="addCashUse"
      max-width="600px"
      persistent
    >
      <v-card class="pa-4">
        <div class="d-flex">
          <div
            v-if="inOurOut === 1"
            class="text-h3 font-weight-bold"
          >
            {{ $t('CashReceipts') }}
          </div>
          <div
            v-else
            class="text-h3 font-weight-bold"
          >
            {{ $t('CashOut') }}
          </div>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="addCashUse = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-form
          ref="useOfCash"
          v-model="valid"
          lazy-validation
        >
          <div class="mt-2 mb-1">
            {{ $t('Designation') }}
          </div>
          <v-text-field
            v-model="cashUseName"
            dense
            hide-details
            outlined
            required
          />
          <div class="mb-1">
            {{ $t('Account') }}
          </div>
          <v-text-field
            v-model="cashUseCode"
            dense
            hide-details
            outlined
            required
          />
        </v-form>
        <div class="d-flex">
          <v-spacer/>
          <v-btn
            v-if="inOurOut === 1"
            class="primary  mt-4 lighten-2"
            elevation="0"
            style="border-radius: 36px"
            @click="save(1)"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            v-else
            class="primary  mt-4 lighten-2"
            elevation="0"
            style="border-radius: 36px"
            @click="save(2)"
          >
            {{ $t('confirm') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import {
    addUseOfCash,
    deleteUseOfCash,
    editUseOfCash,
    exportManyDatevCsv,
    getCashUseful,
    showAllDatevSettings,
    updateDatevSettings,
  } from '@/common/Utlis/api'
  import { showSuccessMessage, sureToDelete } from '@/common/utils'
  import dayjs from 'dayjs'
  import GlobalConfig, { getNgrokUrl } from '@/GlobalSettings'
  import Payment from '@/model/common/Payment'
  import Utils from '@/common/Utlis/Utils'
  import PageHeader from '@/composable/InK/typography/PageHeader'
  import i18n from '@/i18n'
  import FabButton from '@/views/AadenPages/components/FabButton/FabButton'

  export default {
    name: 'Datev',
    components: {
      FabButton,
      PageHeader,
    },
    data: function () {
      return ({
        inOurOut: 1,
        tab: null,
        cashUseType: null,
        cashUseName: null,
        cashUseCode: null,
        items: [{
          key: 1,
          value: '收入到kasse',
        }, {
          key: 2,
          value: '从kasse支出',
        }],
        addCashUse: false,
        cashUseful: [],
        paymentList: null,
        maxMonth: null,
        downloadUrl: null,
        selectDate: null,
        picker: new Date().toISOString().substr(0, 7),
        datevForm: null,
        emailAddress: null,
        sendStatus: null,
        edit: false,
        loading: false,
        valid: true,
      })
    },
    computed: {
      canDownMonth () {
        return dayjs(this.picker).diff(this.maxMonth, 'month')
      },
      leftCashUse () {
        return this.cashUseful.filter(it => it.lrMark === '1')
      },
      rightCashUse () {
        return this.cashUseful.filter(it => it.lrMark === '2')
      },
      kontoForms () {
        return this.datevForm?.filter(it => it.fieldName.toLowerCase() === 'konto 7' || it.fieldName.toLowerCase() === 'konto 19' || it.fieldName.toLowerCase() === 'konto 0') ?? []
      },
      notKontoForms () {
        return this.datevForm?.filter(it => !it.fieldName.toLowerCase().includes('konto')) ?? []
      },
    },

    watch: {
      addCashUse (val) {
        if (!val) {
          this.cashUseType = ''
          this.cashUseName = ''
          this.cashUseCode = ''
        }
      },
    },
    async mounted () {
      await this.initialize()
      this.maxMonth = dayjs(this.picker).add(-1, 'month').startOf('month').format('YYYY-MM')
    },
    methods: {
      openAddDialog (item) {
        this.inOurOut = item
        this.addCashUse = true
      },
      async save (item) {
        await addUseOfCash({
          account: this.cashUseCode,
          name: this.cashUseName,
          lrMark: item,
        })
        await this.initialize()
        this.addCashUse = false
        showSuccessMessage(i18n.t('AddSuccess'))
      },
      async deleteUseOfCash (item) {
        await sureToDelete(async () => {
          await deleteUseOfCash(item.id)
        })
        showSuccessMessage(i18n.t('delete_success'))
        await this.initialize()
      },
      async sendDatev () {
        const fromDate = dayjs(this.picker).format('YYYY-MM-DD HH:mm:ss')
        const toDate = dayjs(this.picker).add(1, 'month').add(1, 'second').format('YYYY-MM-DD HH:mm:ss')
        await exportManyDatevCsv(fromDate, toDate)
        showSuccessMessage(i18n.t('submit_success'))
      },
      async downDatevCsv () {
        this.selectDate = dayjs(this.picker).add(1, 'month').add(4, 'hour').format('YYYY-MM-DD HH:mm:ss')
        window.open(getNgrokUrl(GlobalConfig.DeviceId) + '/PHP/Datev.php?op=exportAndDownloadDatevCsv&now=' + this.selectDate, '_blank')
      },

      async saveConfirm () {
        let hasEmptyItem = false

        if (this.datevForm.find(_ => _.isRequired === '1' && _.value === '')) {
          hasEmptyItem = true
          Utils.showError(i18n.t('EmptyInput'))
          return false
        }
        if (!hasEmptyItem) {
          updateDatevSettings(this.datevForm).then(() => {
            Utils.toast(this.$t('submit_success'))
          })
        }
        for (const item of this.paymentList) {
          await Payment.edit(item)
        }
        for (const item of this.leftCashUse) {
          await editUseOfCash(item, item.id)
        }
        for (const item of this.rightCashUse) {
          await editUseOfCash(item, item.id)
        }
      },

      async initialize () {
        this.datevForm = await showAllDatevSettings()
        const payments = await Payment.getList()
        const positivePayments = payments.filter(it => it.id > 0).filter(it => it.id !== 9 && it.id !== 4)
        const negativePayments = payments.filter(it => it.id < 0)
        this.paymentList = positivePayments.concat(negativePayments)
        this.cashUseful = (await getCashUseful()).filter(it => !it.deletedAt)
        this.emailAddress = this.datevForm.find(_ => _.key === 'sendingToEmail').value
      },

    },
  }

</script>
